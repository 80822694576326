<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button-->
      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Export -->
      <ion-button (click)="export()" color="dark" fill="clear" [hidden]="view.mode === 'pick'"
        [disabled]="!!view.loading">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'export'|translate"></ion-label>
      </ion-button>

      <!-- Add -->
      <ion-button (click)="addTranslation()" color="primary" shape="round" fill="solid"
        [hidden]="view.tab !== 'translations'">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Segment toolbar -->
  <ion-toolbar class="ion-no-border container second-toolbar">
    <ion-segment [(ngModel)]="view.tab" (ionChange)="tabChanged()">

      <ion-segment-button value="translations">
        <ion-icon name="swap-horizontal-outline"></ion-icon>
        <ion-label [innerHTML]="'translations'|translate"></ion-label>
      </ion-segment-button>

      <ion-segment-button value="corrections">
        <ion-icon name="sync-outline"></ion-icon>
        <ion-label [innerHTML]="'corrections'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.items" [options]="selectionOptions" [(view)]="view"
    (onLanguageChanged)="onLanguageChanged($event)"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- No entries card -->
    <pipeline-no-entries-card [cta]="cta" [text]="'translations_admin_cta_text'|translate"
      *ngIf="view.items && !view.items.length">
    </pipeline-no-entries-card>

    <!-- Loading spinner -->
    <ion-spinner name="circular" *ngIf="!view.items"></ion-spinner>

    <div *ngIf="!!view.items && !!view.items.length">
      <ion-card *ngFor="let translation of view.items; trackBy:trackItems">

        <ion-grid>
          <ion-row>

            <ion-col size="6">

              <ion-item lines="none" class="ion-text-wrap">
                <ion-checkbox class="ion-text-wrap" justify="start" labelPlacement="end"
                  [(ngModel)]="translation.checked" (ionChange)="onItemCheckboxClicked(translation)"
                  [disabled]="translation.disabled">
                  <p [innerHTML]="translation.key || translation.input" class="ion-text-wrap"></p>
                  <small *ngIf="!!translation.input_language"
                    [innerHTML]="('language_' + translation.input_language)|translate"></small>
                </ion-checkbox>
              </ion-item>

            </ion-col>

            <ion-col size="6">

              <ion-item lines="none">

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="translation.value || translation.output" class="ion-text-wrap"></h3>
                  <p *ngIf="!!translation.output_language"
                    [innerHTML]="('language_' + translation.output_language)|translate"></p>
                </ion-label>

                <ion-button slot="end" icon-only (click)="editTranslation(translation)" fill="clear" color="dark">
                  <ion-icon name="create-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'edit'|translate" [hidden]="!view.isDesktop"></ion-label>
                </ion-button>

                <ion-button slot="end" icon-only (click)="deleteTranslation(translation)" fill="clear" color="danger">
                  <ion-icon name="trash-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'delete'|translate" [hidden]="!view.isDesktop"></ion-label>
                </ion-button>

              </ion-item>

            </ion-col>

          </ion-row>
        </ion-grid>

      </ion-card>
    </div>

  </div>

</ion-content>