import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from "src/app/services/core/events.service";
import { LanguageService } from "src/app/services/core/language.service";
import { ProjectsService } from "src/app/services/core/projects.service";
import { SettingsService } from "src/app/services/core/settings.service";
import { UserService } from "src/app/services/core/user.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-languages-select',
  standalone: false,
  templateUrl: './languages-select.component.html',
  styleUrls: ['./languages-select.component.scss']
})
export class LanguagesSelectComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() disabled: boolean;
  @Input() icon: string;
  @Input() input: string | string[];
  @Input() label: string;
  @Input() options: languagesSelectOptions;

  @Output() changed = new EventEmitter();

  languages: any[] = [];

  view: any = {};

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private language: LanguageService,
    private projects: ProjectsService,
    private settings: SettingsService,
    private user: UserService,
    private viewService: ViewService,
  ) {
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async getCachedLanguageByViewParams() {
    const userLanguage: string = await this.language.getDisplayLanguage();
    let defaultLanguage: any = (!!this.options && this.options.hasOwnProperty('default') ? (this.options.default === null ? this.input : this.options.default) : userLanguage);

    if (!!this.options && !!this.options.mode) {
      switch (this.options.mode) {
        case 'request':
          defaultLanguage = await this.language.getRequestLanguage();
          break;
      }
    }

    const cacheKey: string = await this.getCacheKey();
    const fromCache: cacheItem | null = (!!this.options && !!this.options.cached ? await this.cache.get(cacheKey, -1) : null);

    return (!!fromCache && !!fromCache.data ? fromCache.data : defaultLanguage);
  }

  async getCacheKey() {
    const project: project = await this.projects.getCurrent();
    return `language_select_${!!project ? project.uid || 0 : 0}`;
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', async (project: project) => {
      this.view.languagesSelectProject = project;

      this.loadLanguages();
      await this.loadKey();
    });

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  async loadKey() {
    let key: any = await this.getCachedLanguageByViewParams();

    if (!!this.options && !!this.options.multiple && (typeof this.input === 'string')) {
      key = [this.input];
    }

    if (key !== this.input) {
      this.input = key;
      this.changed.emit(this.input);
    }
  }

  async loadLanguages() {
    try {
      let languages: any = await this.language.getLanguages();
      const blAll: boolean = (!!this.options && !!this.options.all);

      if (!blAll && !!this.view && !!this.view.languagesSelectProject && !!this.view.languagesSelectProject.config && !!this.view.languagesSelectProject.config.languages && !!this.view.languagesSelectProject.config.languages.length) {
        let projectLanguages: string[] = this.view.languagesSelectProject.config.languages;

        // add main language to project languages list if set
        if (!!this.view.languagesSelectProject.language && (projectLanguages.indexOf(this.view.languagesSelectProject.language) === -1)) {
          projectLanguages.push(this.view.languagesSelectProject.language);
        }

        languages = (languages || [])
          .filter((language: language) => {
            return (projectLanguages.indexOf(language.indent) !== -1);
          });
      }

      this.languages = (languages || []);
    } catch (e) {
      this.events.publish(e);
    }
  }

  async loadProject() {
    this.view.languagesSelectProject = await this.projects.getCurrent();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.loadKey();
    this.calcViewVars();
    this.initEvents();
    this.loadProject();
    this.loadLanguages();
  }

  pick() {

    if (!!this.disabled || !!this.view.loading) {
      return false;
    }

    const options: any = Object.assign(this.options || {}, {
      data: (this.languages || []).map((item: any) => {
        item.checked = (!!this.input && !!this.input.length && (this.input.indexOf(item.indent) !== -1));

        /*
        if(!!item.flag) {
          item.photo = item.flag;
        }
        */

        return item;
      }),
      multiple: !!this.options && !!this.options.multiple,
    });

    this.language.pick(options)
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.items && !!response.data.items.length && (response.data.items.length > 1)) {

          this.input = response.data.items.map((item: any) => {
            return `${item.indent}`;
          });

          this.updateLanguage();
        } else
          if (!!response && !!response.data && !!response.data.item) {
            this.input = (!!this.options && !!this.options.multiple ? [`${response.data.item.indent}`] : `${response.data.item.indent}`);
            this.updateLanguage();
          }

      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async storeToCache() {

    const cacheKey: string = await this.getCacheKey(),
      value: string = `${this.input || ''}`;

    this.cache.set(cacheKey, value);
  }

  async updateLanguage() {

    if (!!this.options && !!this.options.cached) {
      await this.storeToCache();
    }

    switch (this.options.mode) {

      case 'display':
        this.language.setDisplayLanguage(this.input as string);
        this.changed.emit(this.input);
        break;

      case 'request':
        this.language.setRequestLanguage(this.input as string);
        this.changed.emit(this.input);
        break;

      case 'user':
        let user: user = this.user.getUser() || {};

        if (!user || !user.uid || (user.uid === -1) || !this.input) {
          return false;
        }

        user.classifications = (user.classifications || {}) as userClassifications;
        user.classifications.language = `${this.input || ''}`;

        this.user.setUser(user, true)
          .then(() => {
            this.changed.emit(this.input);
            this.settings.showUpdatedToast();
          })
          .catch((error: any) => {
            this.events.publish('error', error);
          });

        break;

      case 'view':
        this.changed.emit(this.input);
        break;

    }

  }

}