import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AiCoderService } from 'src/app/services/ai/ai-coder.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { EventsService } from "src/app/services/core/events.service";
import { ViewService } from 'src/app/services/core/view.service';

import { proxyUrl } from 'src/config/variables';

import { EditorComponentModule } from 'src/app/components/generic/editor/editor.component.module';

@Component({
  selector: 'pipeline-ai-coder-card',
  standalone: false,
  templateUrl: './ai-coder.component.html',
  styleUrls: ['./ai-coder.component.scss']
})
export class AiCoderComponent implements AfterViewInit, OnDestroy, OnInit {

  aiCoderProject: aiCoderProject = {
    active: true,
    files: [],
    icon: 'rocket-outline',
    name: '',
  };

  aiSettings: aiSettings = {
    context: 'text_generation',
    use_proxy: true,
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_generation'
    ],
  };

  @Input() autostart: boolean = false;

  @Input() colSize: number | null = null;

  @Input() disabled: boolean = false;

  @ViewChild(EditorComponentModule) editor: any;

  fallbackImg: string = './assets/img/fallback.webp';

  @Input() hidden: boolean = false;

  @Input() input: string = '';

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    input_placeholder: 'ai_coder_ai_helper_input_placeholder',
    headline: 'ai_coder_ai_helper_text',
    showAiCreate: true,
    showAiSettings: true,
    showInput: true,
  };

  proxyUrl: string;

  rebuild: boolean = false;

  response: string = '';

  @Input() size: number = 12;

  view: any = {
    editorMode: 'code',
    segment: 'code',
  };

  constructor(
    public ai: AiToolsService,
    private aiCoder: AiCoderService,

    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private viewService: ViewService,
  ) {
    this.proxyUrl = proxyUrl;
  }

  aiCreate(options: any = {}) {
    options.init = options.init !== false;
    options.input = options.input || this.input;

    if (!options.input || !options.input.length) {
      return false;
    }

    this.aiCoderProject = this.aiCoderProject || {};
    this.aiCoderProject.name = this.aiCoderProject.name || `${options.input || ''}`;

    this.view.generating = true;
    this.view.coding = true;
    this.view.progress = 0;
    this.view.showSplineView = true;

    const params: any = {
      history: (options.history || []),
    };

    this.startManually();

    this.aiCoder.executeCreateCode(options, params)
      .then(async (response: any) => {

        if (!!options.init) {
          this.aiCoderProject.files = (!!response && !!response.files ? response.files : []);
        }

        if (!!response && !!response.output) {
          const contentLines: string[] = `${response.output || ''}`.split("\n");
          const blShouldBeautify: boolean = `${contentLines[0] || ''}`.indexOf('`') === 0;

          let outputFormat: string = `${contentLines[0] || ''}`.replace('```', '');
          let initCode: string | null = response.output;

          if (!outputFormat && !!initCode && (
            (initCode.toLocaleLowerCase().indexOf('<!doctype') !== -1) ||
            (initCode.toLocaleLowerCase().indexOf('<html') !== -1))
          ) {
            outputFormat = 'html';
          }

          // detect code in output and beautify it
          if (blShouldBeautify) {
            initCode = `${response.output || ''}`.replace(`${contentLines[0]}\n`, '').replace('```', '');
          }

          initCode = `${initCode || ''}`.replace('```html', '').replace('```', '');

          if (!!initCode) {

            if (!!this.aiSettings.use_proxy) {
              this.view.code = this.aiCoder.addProxyUrlToResources(initCode || '');
            } else {
              this.view.code = this.aiCoder.removeProxyUrlToResources(initCode || '');
            }

            this.view.codeLanguage = (outputFormat || 'html');
            this.updateEditor();

            switch (outputFormat) {
              case 'html':
                this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${this.view.code}`);
                break;
              default:

                const simulate: any = await this.aiCoder.simulate(`${this.view.code}`);
                console.log('ai-coder: simulate response', simulate);

                if (!!simulate && !!simulate.output) {
                  this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${simulate.output}`);
                } else {
                  this.view.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${this.view.code}`);
                }

                break;
            }
          }
        }

        this.view.buttonAction = 'rebuild';
        this.view.generating = false;
        this.view.coding = false;
        this.view.showSplineView = false;

        this.calcViewVars();
      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.coding = false;
        this.view.showSplineView = false;

        this.events.publish('error', error);
      });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  initEvents() {
    this.view.events = {};
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    if (!!this.autostart && !!this.input) {
      this.aiCreate();
    }
  }

  onEditorInputChanged(value: string, key: string = 'input') {
    this.view.code = `${value || ''}`;
  }

  segmentChanged() {

  }

  startManually() {
    this.view.startManually = true;
  }

  updateEditor() {
    try {
      if (!!this.editor) {
        this.editor.setInput(this.view.code);
      }
    } catch (e) {
      console.warn('updating editor failed', e);
    }
  }

}