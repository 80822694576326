<ion-item button [disabled]="view.loading" lines="none" (click)="pick()" class="language-picker"
  [class.allow-mini]="!!options && !!options.allowMini">

  <!-- Language icon -->
  <ion-icon [name]="icon || 'language-outline'" slot="start" color="primary"></ion-icon>

  <!-- Language(s) label -->
  <ion-label class="ion-text-wrap" [hidden]="!view.isDesktop && (!!options && !!options.allowMini)"
    [innerHTML]="(label || (!!options && !!options.multiple ? 'languages' : 'language'))|translate"></ion-label>

  <!-- Side note -->
  <ion-note slot="end" *ngIf="!options || !options.multiple"
    [innerHTML]="(!!input ? ('language_' + input) : 'select')|translate"></ion-note>

  <!-- Languages list (if multiple) -->
  <div *ngIf="!!options && !!options.multiple && !!input && !!input.length">
    <ion-chip slot="end" *ngFor="let item of input" [hidden]="!item"
      [innerHTML]="('language_' + item)|translate"></ion-chip>
  </div>

</ion-item>